import { graphql } from 'gatsby'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { VideoPlayer } from '../../components/common/video-player'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Kontakty a spolupráce s ostatními" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Kontakty a spolupráce s ostatními" />
        <ContentSubPage>
          <p>
            Jak spolupracovat s ostatními v rámci aplikace Flagis? Jak si
            přidávat kontakty a posílat úkoly? Je to snadné a efektivní.
            Podívejte se na naše nové video, ve kterém vám vše vysvětlí
            muzikant, kreativec a moderátor Filip Bartek.
          </p>
          <br />
          <VideoPlayer url="https://www.youtube.com/watch?v=l8YP4fPD0tw" />
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
